.body{
    max-width: 100vw !important;
overflow-x: hidden !important;
}
/* .footer-default{
    background-color: rgba(61, 205, 224, 0.52) !important;
} */
.mainHeading{
    font-weight: bolder;
}
.cardHeading{
    font-family: "Quintessential";
}
.featuresList{
    color: black !important;
}
.productImg{
    height:30vh;
     object-fit: cover !important;
     object-position:50% 0% !important; 
}
@media(max-width:767px){
.image-aboutPage{
    margin-bottom: 30px !important;
 }
 .description.featuresList{
     display: none !important;
 }
}

@media(min-width:767px){
    .landingFeatures{
        height: 20vh !important;
        overflow-y: visible;
    }
}

@media(max-width:990px){
    .productImg{
        height:20vh !important;
    }

}
@media(max-width:740px){
    .productImg{
        height:18vh !important;
    }
}
@media(max-width:420px){
    .productImg{
        height:15vh !important;
    }
}
/* .about-main{
    background-image: url('../img/aboutbg.jpeg');
	background-size:  cover;
	background-repeat: no-repeat;
    background-position: center center;
} */
.borderYtoX li{ 
    position: relative;
    z-index: 0;}
/* 
.borderYtoX li:before, .borderYtoX li:after
{   display: inline-block ;
    position: absolute !important;
    opacity: 0.5 !important;
    height: 100% !important;
    width: 2px !important;
    content: '' !important;
    background: rgb(214, 40, 40) !important;
    transition: all 0.3s !important;
}

.borderYtoX li:before
{       
    left: 0px !important;
    top: 0px !important;
}

.borderYtoX li:after
{
    right: 0px !important;
    bottom: 0px !important;
}

.borderYtoX li:hover:before, .borderYtoX li:hover:after
{
    opacity: 1 !important; 
    height: 2px !important;
    width: 100% !important;
}
 */

 .borderYtoX li.active::before,  .borderYtoX li.active::after{
    opacity: 1;
    /* transform: translateY(0px);  */
    width: 100%;

 }

.borderYtoX li:before, .borderYtoX li:after
{
    position: absolute;
    opacity: 0;
    width: 0%;
    height:2px;
    content: '';
    background: rgb(0,255,230);
    transition: all 0.6s;
}

.borderYtoX li:before
{
    left: 0px;
    top: 0px;
}

.borderYtoX li:after
{
    right: 0px;
    bottom: 0px;
}

.borderYtoX li:hover:before, .borderYtoX li:hover:after
{
    opacity: 1;
    width: 100%;
}

/* 
.borderYtoX li:before, .borderYtoX li:after
{
    position: absolute;
    left: 0px;
    width: 100%;
    height: 2px;
    background: rgb(6, 139, 151);
    content: "";
    opacity: 0;
    transition: all 0.3s;
}

.borderYtoX li:before
{
    top: 0px;
    transform: translateY(10px);
}

.borderYtoX li:after
{
    bottom: 0px;
    transform: translateY(-10px);
}

.borderYtoX li:hover:before, .borderYtoX li:hover:after
{
    opacity: 1;
    transform: translateY(0px);
} */
